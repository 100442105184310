import { Divider, Flex, HStack, Text } from 'native-base'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import ActivityCard from '../../_shared/card/ActivityCard'
import FaIcon from '../../_shared/FaIcon'
import { ActivityCardBorderState } from '../../../../../domain/models/interfaces/activityInterfaces'

const PathStep = ({
  step,
  index,
  firstIncompleteStep,
  goToActivity,
  dataLength,
}) => {
  const stepNumberBorderColor = (step, index) => {
    if(step.completed) return 'white'
    else if(firstIncompleteStep === index) return 'secondary.500'
    else return 'muted.300'
  }

  const shimBorderState = (step, index) => {
    if(index > firstIncompleteStep) step.cardBorderState = ActivityCardBorderState.COMPLETED
    return step
  }

  return (
    <HStack key={index} space="4">
      <Flex>
        <Flex flex="1" mb="2">
          {index > 0 && (
            <Divider bg="muted.300" orientation="vertical" w="0.5" mx="auto" />
          )}
        </Flex>
        <HStack
          justifyContent="center"
          alignItems="center"
          borderColor={stepNumberBorderColor(step, index)}
          borderWidth={1}
          bg={step.completed ? 'secondary.500' : 'white'}
          borderRadius="full"
          width={6}
          height={6}>
          {step.completed ? (
            <FaIcon name="check" color="white" size={12} />
          ) : (
            <Text color={firstIncompleteStep === index ? 'secondary.500' : 'muted.300'}>
              {index + 1}
            </Text>
          )}
        </HStack>
        <Flex flex="1" mt="2">
          {index < dataLength - 1 && (
            <Divider bg="muted.300" orientation="vertical" w="0.5" mx="auto" />
          )}
        </Flex>
      </Flex>
      <Flex flex="1" my="2.5">
        <ActivityCard
          activity={shimBorderState(step, index)}
          onPress={
            firstIncompleteStep >= index ? () => goToActivity(step.id) : null
          }
        />
      </Flex>
    </HStack>
  )
}

export default PathStep
