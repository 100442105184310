import { Alert, Button, Checkbox, FlatList, FormControl, Heading, Input, Modal, Text } from 'native-base'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Linking } from 'react-native'
import Configuration from '../../../domain/services/ConfigurationService'
import Reports from '../components/admin/Reports'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import useAuthentication from '../hooks/authentication/useAuthentication'
import useInvalidateQueries from '../hooks/useInvalidateQueries'
import DefaultLayout from '../layouts/DefaultLayout'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'

const AdminScreen = ({ navigation }) => {
  const { userProfile } = useUserProfileContext()
  const { logout } = useAuthentication()
  const { TestApi, IngestApi, AdminApi } = useCabanaApi()
  const { invalidateQueries } = useInvalidateQueries()

  useEffect(() => {
    if (!userProfile.admin) {
      if (navigation.canGoBack()) navigation.goBack()
      else navigation.navigate('Home')
    }
  }, [userProfile, navigation])

  const nameRef = useRef(null)
  const [createRoomVisible, setCreateRoomVisible] = useState(false)
  const [saving, setSaving] = useState(false)

  const allActions = [
    'Content',
    'EAP',
    'Live Group Schedule',
    'Moderators',
    'Organizations',
    'Professional Roles',
    'Tags',
    'Voyages'
  ]

  const [meetNowError, setMeetNowError] = useState()
  const [ingesting, setIngesting] = useState(false)
  const [ingestActions, setIngestActions] = useState(allActions)
  const [ingestMessage, setIngestMessage] = useState(undefined)
  const [ingestAll, setIngestAll] = useState(true)

  const airtableIngest = () => {
    setIngesting(true)
    setIngestMessage(undefined)
    IngestApi.ingest(ingestActions)
      .then(setIngestMessage)
      .finally(() => setIngesting(false))
  }

  const meetNow = () => {
    setSaving(true)
    setMeetNowError(undefined)
    TestApi.meetNow({ title: nameRef.current.value })
      .then((activityId) => {
        invalidateQueries('Activity')
        navigation.navigate('Activity', { activityId })
        setCreateRoomVisible(false)
        nameRef.current.value = ''
      })
      .catch(setMeetNowError)
      .finally(() => {
        setSaving(false)
      })
  }

  const handleFeatureFlag = async () => {
    const url = `${Configuration.CABANA_SERVICE_REST_URL}/feature-console/index`
    const urlObj = new URL(url)

    urlObj.username = 'feature-console'
    urlObj.password = Configuration.FEATURE_CONSOLE_PASSWORD

    Linking.openURL(
      urlObj.toString()
    )
  }

  const flatListHeader = useMemo(
    () => (
      <>
        <Heading key='heading1' size='lg' textAlign='center' mt='5'>
          Welcome, {userProfile.email}!
        </Heading>
        <Heading key='heading2' size='md' textAlign='center' mb='5'>
          User ID: {userProfile.id}
        </Heading>
        <Button
          key='logoutButton'
          mx='auto'
          onPress={() =>
            logout({
              clientId: Configuration.AUTH0_CLIENT_ID,
              logoutParams: {
                returnTo: Configuration.CABANA_UI
              }
            })
          }>
          Logout
        </Button>
        <Heading key='heading3' size='lg' mt='5'>
          Airtable
        </Heading>
        <Checkbox.Group
          onChange={setIngestActions}
          value={ingestActions}
          accessibilityLabel='Airtable ingest'>
          <Checkbox value='Organizations' isDisabled={ingestAll}>
            Organizations
          </Checkbox>
          <Checkbox value='Professional Roles' isDisabled={ingestAll}>
            Professional Roles
          </Checkbox>
          <Checkbox value='Tags' isDisabled={ingestAll}>
            Tags
          </Checkbox>
          <Checkbox value='EAP' isDisabled={ingestAll}>
            EAP
          </Checkbox>
          <Checkbox value='Moderators' isDisabled={ingestAll}>
            Moderators
          </Checkbox>
          <Checkbox value='Content' isDisabled={ingestAll}>
            Content
          </Checkbox>
          <Checkbox value='Live Group Schedule' isDisabled={ingestAll}>
            Live Group Schedule
          </Checkbox>
          <Checkbox value='Voyages' isDisabled={ingestAll}>
            Voyages
          </Checkbox>
        </Checkbox.Group>
        <Checkbox
          value={''}
          defaultIsChecked
          onChange={(checked) => {
            if (checked) {
              setIngestActions(allActions)
            } else {
              setIngestActions([])
            }
            setIngestAll(checked)
          }}>
          Ingest All
        </Checkbox>
        {!!ingestActions.find((action) => action === 'Live Group Schedule') && (
          <Text fontSize='lg' mt={5} color='error.600'>
            Do not ingest live groups if one is scheduled to start in the next
            30 minutes
          </Text>
        )}
        <Button
          key='ingestButton'
          mr='auto'
          onPress={airtableIngest}
          isLoading={ingesting}
          isLoadingText='Ingesting'
          isDisabled={ingestActions.length === 0}
          my='5'>
          Ingest From Airtable
        </Button>
        {ingestMessage && (
          <FlatList
            data={ingestMessage}
            keyExtractor={(message) => message}
            renderItem={(message) => <Text>{message.item}</Text>}
          />
        )}
        <Heading key='heading5' size='lg' mb='1'>
          Live Groups
        </Heading>
        <Button
          key='makeRoom'
          mr='auto'
          mb='5'
          onPress={() => setCreateRoomVisible(true)}>
          Meet Now
        </Button>
        <Heading key='heading6' size='lg' mb='1'>
          Voyages
        </Heading>
        <Button
          key='resetVoyage'
          mr='auto'
          mb='5'
          onPress={AdminApi.resetVoyage}>
          Reset my voyage
        </Button>

        <Heading key='heading7' size='lg' mb='1'>
          Feature Flag
        </Heading>
        <Button
          key='featureFlag'
          mr='auto'
          mb='5'
          onPress={handleFeatureFlag}>
          Feature Flag console
        </Button>

        <Reports />
      </>
    ),
    [userProfile, airtableIngest, ingestMessage, ingesting, logout]
  )

  return (
    <DefaultLayout activeLabel='Admin' hasScrollBar={true}>
      <FixedContainer size='md' props={{ px: '5' }}>
        {flatListHeader}
        <Modal isOpen={createRoomVisible} size='lg' initialFocusRef={nameRef}>
          <Modal.Content>
            <Modal.CloseButton onPress={() => setCreateRoomVisible(false)} />
            <Modal.Header>Create Room</Modal.Header>
            <Modal.Body>
              <FormControl>
                <FormControl.Label>Name</FormControl.Label>
                <Input ref={nameRef} onSubmitEditing={meetNow} />
              </FormControl>
              {meetNowError && (
                <Alert colorScheme='error' variant='subtle'>
                  <Text w='100%' color='text.900'>
                    {meetNowError}
                  </Text>
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant='ghost'
                  onPress={() => {
                    setCreateRoomVisible(false)
                    setMeetNowError(undefined)
                  }}>
                  Cancel
                </Button>
                <Button onPress={meetNow} isLoading={saving}>
                  Save
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </FixedContainer>
    </DefaultLayout>
  )
}

export default AdminScreen
