import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import Markdown from 'react-native-marked'
import { Group } from '../../../../domain/models/interfaces/activityInterfaces'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import mdStylesDesktop from '../../constants/mdStylesDesktop'
import mdStylesMobile from '../../constants/mdStylesMobile'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import { MemberScreenParams } from '../../screens/MemberScreen'
import PathCard from '../voyages/path/PathCard'
import ActivityCard from '../_shared/card/ActivityCard'
import GroupCard from '../_shared/card/GroupCard'
import FaIcon from '../_shared/FaIcon'

interface CollectionModel {
  title: string
  description: string
  bannerImage: string
  author: {
    name: string
    photo: string
  }
  groups: []
  content: []
  paths: []
}

const Collection = ({ slug }: { slug: string }) => {
  const { CollectionApi } = useCabanaApi()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isMobile = useMobileBreakpoint()
  const [loaded, setLoaded] = useState(false)
  const [collection, setCollection] = useState<CollectionModel>({
    title: undefined,
    description: undefined,
    bannerImage: undefined,
    author: {
      name: undefined,
      photo: undefined,
    },
    groups: [],
    content: [],
    paths: [],
  })

  const cardWidth = useBreakpointValue({
    base: 'unset',
    md: 300,
  })

  const cardDirection = useBreakpointValue({
    base: 'column',
    md: 'row',
  })

  const containerSpace = isMobile ? 0 : 10
  const sectionSpace = isMobile ? 4 : 0

  const loadCollection = async () => {
    setLoaded(false)

    const result = await CollectionApi.collection(slug)
    setCollection(result)
    analytics.track('Collection Viewed', { Title: result.title })

    setLoaded(true)
  }

  const gotoGroup = (group: Group) => {
    navigation.navigate('Activity', { activityId: group.id })
  }

  useEffect(() => {
    loadCollection()
  }, [slug])

  return (
    <VStack
      borderRadius={isMobile ? 0 : '20px'}
      borderColor="grey.200"
      borderWidth={1}
      my={containerSpace}
      width={'full'}
      padding={containerSpace}
      space={10}
      backgroundColor={'white'}>
      {/* banner image */}
      <Skeleton height={80} isLoaded={loaded}>
        <Image
          borderRadius={containerSpace}
          width={'full'}
          height={80}
          source={{ uri: collection.bannerImage }}
        />
      </Skeleton>

      <VStack space={4} px={sectionSpace}>
        <HStack>
          {/* collection tag */}
          <Skeleton rounded={'full'} height={6} width={40} isLoaded={loaded}>
            <HStack
              backgroundColor={'gray.200'}
              paddingX={3}
              paddingY={1}
              borderRadius={12}
              space={1}
              alignItems={'center'}>
              <FaIcon color={'text.900'} name="list" />
              <Text color={'text.900'}>COLLECTION</Text>
            </HStack>
          </Skeleton>
        </HStack>
        {/* author */}
        <HStack alignItems={'center'} space={2}>
          <Skeleton rounded={'full'} height={8} width={8} isLoaded={loaded}>
            <Image source={{ uri: collection.author.photo }} size={8} />
          </Skeleton>
          <Skeleton.Text lines={1} width={20} height={4} isLoaded={loaded}>
            <Text color={'text.900'} fontSize={16}>
              {collection.author.name}
            </Text>
          </Skeleton.Text>
        </HStack>
        <VStack pb={4} space={2}>
          {/** title */}
          <Skeleton.Text lines={1} _line={{ height: 8 }} isLoaded={loaded}>
            <Heading color={'text.900'}>{collection.title}</Heading>
          </Skeleton.Text>
          {/** description */}
          <Skeleton.Text lines={2} size={4} isLoaded={loaded}>
            <Markdown
              color={'text.900'}
              value={collection.description}
              flatListProps={{
                contentContainerStyle: { backgroundColor: 'white' },
              }}
              styles={{ ...(isMobile ? mdStylesMobile : mdStylesDesktop) }}
            />
          </Skeleton.Text>
        </VStack>
      </VStack>

      {/** live groups */}
      {(!loaded || collection.groups.length > 0) && (
        <VStack space={4} px={sectionSpace}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={loaded}>
            <Heading size={'md'} color={'text.900'}>
              Live groups
            </Heading>
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={loaded}>
            <Text fontSize={'xs'} color={'text.900'}>
              Drop into one of our daily, live groups to connect with others on
              shared challenges.
            </Text>
          </Skeleton.Text>
          <HStack
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {!loaded &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection.groups.map((group) => (
              <GroupCard
                key={group.id}
                activity={group}
                width={cardWidth}
                onPress={async () => gotoGroup(group)}
              />
            ))}
          </HStack>
        </VStack>
      )}

      {/** content */}
      {(!loaded || collection.content.length > 0) && (
        <VStack space={4} px={sectionSpace}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={loaded}>
            <Heading size={'md'} color={'text.900'}>
              Quick learning
            </Heading>
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={loaded}>
            <Text fontSize={'xs'} color={'text.900'}>
              Discover quick, engaging, and fun content that boosts your mental
              health and well-being!
            </Text>
          </Skeleton.Text>
          <HStack
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {!loaded &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection.content.map((content) => (
              <Box key={content.id} width={cardWidth} mb={isMobile ? 0 : 2}>
                <ActivityCard
                  activity={content}
                  onPress={async () => gotoGroup(content)}
                />
              </Box>
            ))}
          </HStack>
        </VStack>
      )}

      {/** paths */}
      {(!loaded || collection.paths.length > 0) && (
        <VStack space={4} px={sectionSpace} mb={sectionSpace}>
          <Skeleton.Text
            lines={1}
            width={40}
            _line={{ height: 6 }}
            isLoaded={loaded}>
            <Heading size={'md'} color={'text.900'}>
              Voyage paths
            </Heading>
          </Skeleton.Text>
          <Skeleton.Text lines={1} width={300} isLoaded={loaded}>
            <Text fontSize={'xs'} color={'text.900'}>
              Voyages offers 5-15 minute guided learning experiences based on
              scientifically backed methodologies to meet your struggles, in the
              moment.
            </Text>
          </Skeleton.Text>
          <HStack
            style={{ gap: 8 }}
            flexWrap={'wrap'}
            alignItems={'stretch'}
            flexDirection={cardDirection}>
            {!loaded &&
              [1, 2, 3].map((i) => <Skeleton key={i} width={cardWidth} />)}
            {collection.paths.map((path) => (
              <PathCard key={path.id} path={path} width={cardWidth} />
            ))}
          </HStack>
        </VStack>
      )}
    </VStack>
  )
}

export default Collection
