import { StatusBar, View } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import VibeCheckForm from '../components/vibeCheck/VibeCheckForm'
import FormLayout from '../layouts/FormLayout'

interface VibeCheckScreenProps {
  closeModal?: () => void
  isProfileScreen?: boolean
}

const VibeCheckScreen = ({
  closeModal,
  isProfileScreen,
}: VibeCheckScreenProps) => {
  const { height } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()

  return (
    <View keyboardShouldPersistTaps="always" flex={1}>
      <FormLayout
        minH={!isMobile && height <= 720 ? '600px' : '700px'}
        padding="0"
        hasSafeAreaY={false}
        hasScrollBar={false}
        autoBackButton={false}>
        <StatusBar barStyle="light-content" />
        <VibeCheckForm
          closeModal={closeModal}
          isProfileScreen={isProfileScreen}
        />
      </FormLayout>
    </View>
  )
}

export default VibeCheckScreen
