import { Box } from 'native-base'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import Collection from '../components/collection/Collection'
import BackButton from '../components/navigation/BackButton'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import DefaultLayout from '../layouts/DefaultLayout'
import { useFeatures } from '../providers/FeatureProvider'
import NotFoundScreen from './NotFoundScreen'

const CollectionScreen = ({ route }) => {
  const { slug } = route.params
  const { features } = useFeatures()
  const isDesktop = useDesktopBreakpoint()

  if (!features.COLLECTIONS) return <NotFoundScreen />
  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop}
      activeLabel=""
      bg={isDesktop ? 'muted.50' : 'bgDefault'}>
      {isDesktop ? (
        <FixedContainer size="md" width="1024px">
          <Collection slug={slug} />
        </FixedContainer>
      ) : (
        <>
          <Box p={4}>
            <BackButton customText="" />
          </Box>
          <Collection slug={slug} />
        </>
      )}
    </DefaultLayout>
  )
}
export default CollectionScreen
