import {
  Box,
  Hidden,
  HStack,
  Image,
  Pressable,
  Spacer,
  View,
} from 'native-base'
import { Platform } from 'react-native'
import LogoHorizontalWhite from '../../assets/images/logos/LogoHorizontalWhite'
import useGetOasisColors from '../../hooks/useGetOasisColors'
import useTabNavigation from '../../hooks/useTabNavigation'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import { useBalancedSelf } from '../../queries/voyageQueries'
import FaIcon from '../_shared/FaIcon'
import NavHeaderLink from './NavHeaderLink'
import { useFeatures } from '../../providers/FeatureProvider'

const NavHeader = ({ activeLabel }) => {
  const { userProfile } = useUserProfileContext()
  const { navigate } = useTabNavigation()
  const logo = require('../../assets/images/logos/logo-horizontal-white.svg')
  const { backgroundColor } = useGetOasisColors()
  const { data, isLoading } = useBalancedSelf()
  const { features } = useFeatures()
  const iPad = Platform.OS === 'ios' && Platform.isPad
  const voyagePath =
    !isLoading && data.screener === 'COMPLETE' ? 'LandingVoyage' : 'Voyage'
  const links = [
    {
      label: 'Today',
      iconName: 'house',
      href: 'Home',
    },
    {
      label: features.NAVBAR_GUIDE ? 'Guide' : 'Explore',
      iconName: 'binoculars',
      href: 'Explore',
    },
    {
      label: 'Voyage',
      iconName: 'lighthouse',
      href: voyagePath,
    },
    {
      label: 'Profile',
      iconName: 'user',
      href: 'Profile',
      subscreen: 'health-minutes',
    },
    {
      label: 'Admin',
      iconName: 'wrench',
      href: 'Admin',
    },
  ]

  return (
    <Hidden till="sm">
      <Box h="64px" bg={backgroundColor} shadow="3">
        <HStack h="100%" alignItems="center" mx="5">
          <Pressable onPress={() => navigate('Home', {})}>
            {iPad ? (
              <View ml={-8} mt={2}>
                <LogoHorizontalWhite />
              </View>
            ) : (
              <Image
                source={logo}
                alt="logo"
                width="160px"
                height="32px"
                mr="6"
              />
            )}
          </Pressable>
          {links.map((link, index) => {
            let render = () => {
              return (
                <NavHeaderLink
                  key={index}
                  label={link.label}
                  iconName={link.iconName}
                  href={link.href}
                  isActive={link.label === activeLabel}
                  subscreen={link.subscreen}
                />
              )
            }
            if (userProfile.admin || link.label !== 'Admin') return render()
          })}
          <Spacer />
          <FaIcon
            props={{ mr: 2 }}
            name="magnifying-glass"
            size={24}
            color="muted.200"
            onPress={() => navigate('Search', {})}
          />
        </HStack>
      </Box>
    </Hidden>
  )
}

export default NavHeader
