import { Box, Flex, HStack, useBreakpointValue } from 'native-base'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { useFeatures } from '../../../providers/FeatureProvider'
import useRoomContext from '../../../providers/RoomProvider'
import RoomAction from '../RoomAction'
import StartRoom from '../StartRoom'
import TechSupport from '../TechSupport'

const RoomFooter = ({ actions }) => {
  const room = useRoomContext()
  const { features } = useFeatures()

  const centerActions = []
  const rightActions = []

  const isCentered = useBreakpointValue({
    base: false,
    lg: true,
  })

  actions.forEach((a) => {
    // Set position
    if (!a.isVisible) return
    else if (a.isMain) centerActions.push(a)
    else rightActions.push(a)
  })

  return (
    <Box
      position="absolute"
      bottom="0"
      h="64px"
      w="100%"
      bg="muted.800"
      pt="3"
      pl="3"
      shadow="3"
      safeAreaBottom>
      {room.me.role !== AttendeeRole.Moderator && (
          <HStack position="absolute" left="0" pl="3" space="3">
            <Flex flex={1}>
              <TechSupport />
            </Flex>
          </HStack>
        )}

      <HStack ml={isCentered && 'auto'} mr="auto" space="3">
        {centerActions.map((action, index) => (
          <Flex key={index}>
            <RoomAction action={action} />
          </Flex>
        ))}
        {room.me.role === AttendeeRole.Moderator && room.active && (
          <StartRoom />
        )}
      </HStack>

      <HStack position="absolute" right="0" pr="3" space="3">
        {rightActions.map((action, index) => (
          <Flex key={index} flex={1}>
            <RoomAction action={action} />
          </Flex>
        ))}
      </HStack>
    </Box>
  )
}

export default RoomFooter
